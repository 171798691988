import axios from "axios";
import { Message, Loading, MessageBox } from "element-ui";
import router from "../router";

let showBox = false;
let showMessage = false
// let loadingInstance;

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // API 的 base_url
  timeout: 5000, // 请求超时时间
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
});

// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 显示加载动画
    // loadingInstance = Loading.service({ fullscreen: true });
    return config;
  },
  (error) => {
    // 隐藏加载动画
    // loadingInstance.close();
    Message.error("请求失败！");
    return Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  async (response) => {
    // 隐藏加载动画
    // loadingInstance.close();
    const res = response.data;
    // 设置token
    if (res.code == -1 || res.msg == '未查询到用户信息') {
      if (!showBox) {
        showBox = true;
        // 跳去登录，并使用模态框提示提示
        await MessageBox.alert(
          "你需要登录才能继续使用该功能，请先登录",
          "提示",
          {
            confirmButtonText: "去登录",
            callback: (e) => {
              // 在这里可以添加跳转到登录页的代码
              setTimeout(() => (showBox = false), 3000);
              if (e == "confirm") {
                router.push("/login");
              } else {
                Message.error("你需要登录才能继续使用该功能，请先登录");
              }
            },
          }
        );
      }
    }
    // Message.success(res.msg);
    if (res.code == undefined) {
      return res
    }
    if (res.code > 0) {
      return res.data;
    } else {
      if (!showMessage) {
        showMessage = true
        Message.error(res.msg);
        setTimeout(() => (showMessage = false), 2000);
      }
      return res;
    }
  },
  (error) => {
    // 隐藏加载动画
    // loadingInstance.close();
    Message.error(error.msg || "响应失败！");
    return Promise.reject(error);
  }
);
/**
 * @data 对象，将对象转换为字符串拼接的方式 {{&}}
 */
const setUrl = (data, str = "?") => {
  for (let key in data) {
    str = str + key + "=" + data[key] + "&";
  }
  return str.slice(0, str.length - 1);
};
class req {
  get = (url, data) =>
    service.get(
      "/index.php" +
      setUrl({
        s: "/api/" + url,
        wxapp_id: process.env.VUE_APP_WXAPP_ID,
        token: localStorage.getItem("krb-token"),
        ...data,
      })
    );
  post = (url, data) =>
    service.post(
      "/index.php" +
      setUrl({
        s: "/api/" + url,
        wxapp_id: process.env.VUE_APP_WXAPP_ID,
        token: localStorage.getItem("krb-token"),
      }),
      data
    );
}
let reqs = new req();
export default reqs;
